import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Stack, Typography, useTheme } from '@mui/material';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import { CropIntelligenceEnum, IImageInfo, ITilingMapField, ReconstructedBandEnum } from 'interfaces/workspace';
import { FC } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeUploadRawImageList,
  changeUploadReconstructedImageList,
  tilingMapSelector,
} from 'store/slices/tilingMapSlice';

interface DragAndDropFileProps {
  description?: string;
  name?: ReconstructedBandEnum;
  form: UseFormReturn<ITilingMapField>;
  disabled?: boolean;
}

const DragAndDropFile: FC<DragAndDropFileProps> = ({ description, name, form, disabled }) => {
  const theme = useTheme();
  const { getValues } = form;
  const dispatch = useAppDispatch();
  const {
    upload: { reconstructedImageList },
  } = useAppSelector(tilingMapSelector);

  const isUploadReconstructedImage = getValues('type') === CropIntelligenceEnum.RECONSTRUCTED_IMAGE;

  const acceptedFile: Accept = isUploadReconstructedImage
    ? {
        'image/tif': ['.tif'],
      }
    : {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
      };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: acceptedFile,
    multiple: !isUploadReconstructedImage,
    onDrop: (files: File[]) => {
      if (disabled) return;
      if (isUploadReconstructedImage) {
        const isExistedFile = reconstructedImageList.find((item) => item.name === name);
        let newReconstructedImageList: IImageInfo[] = [];
        if (isExistedFile) {
          newReconstructedImageList = reconstructedImageList.map((item) =>
            item.name === name ? { ...item, file: files[0] } : item
          );
          dispatch(changeUploadReconstructedImageList(newReconstructedImageList));
        } else {
          newReconstructedImageList = [
            ...reconstructedImageList,
            { name: name!, file: files[0], is3DTexturedSurface: false },
          ];
        }
        dispatch(changeUploadReconstructedImageList(newReconstructedImageList));
      } else {
        dispatch(changeUploadRawImageList(files));
      }
    },
    onDropRejected() {
      toast.error('Drop file failed', { toastId: 1 });
    },
  });

  return (
    <Stack
      {...getRootProps({ className: 'dropzone' })}
      sx={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        border: theme.palette.mode === 'dark' ? '2px dashed red' : '2px dashed #bdbdbd',
        borderColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#bdbdbd',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
      }}>
      <input {...getInputProps()} />
      <Stack sx={{ alignItems: 'center' }}>
        <CloudUploadOutlinedIcon />
      </Stack>
      <Typography sx={{ textAlign: 'center', mt: 1 }}>Drag&Drop files here</Typography>
      {description && <Typography sx={{ textAlign: 'center', mt: 1 }}>{description}</Typography>}
      <ButtonCustom
        title="Browse Files"
        border="0px"
        colorLabel="#FFF"
        sx={{ width: '100px', height: '32px !important', minHeight: '32px !important', mt: 2 }}
        backgroundColor={theme.palette.primary.main}
        onClick={open}
        disabled={disabled}
      />
    </Stack>
  );
};

export default DragAndDropFile;
