import { styled, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { clientLengthUnitMeasurement } from 'components/MapView/RightBar/IssuesTab/utils';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getFissureStatisticsInfo } from 'services/analytics/apiAnalyticsData.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';

interface ISummary {
  totalLength: number;
}

const FissureAnalytics = () => {
  const [totalLength, setTotalLength] = useState<number>(0);
  const theme = useTheme();
  const { analyticId } = useAppSelector(mapViewSelector);
  const { lengthSetting } = useQueryClientSetting();
  const { analyticName } = useAppSelector(rightBarSelector);

  const tableData = [
    {
      key: 1,
      label: 'Total Length',
      value: `${clientLengthUnitMeasurement(totalLength, lengthSetting)} ${lengthSetting?.unit}`,
    },
  ];

  useQuery([QUERY_KEY.GET_FISSURE_STATISTIC, analyticId], () => getFissureStatisticsInfo(analyticId!), {
    enabled: !!analyticId && analyticName === TYPE_ANALYTICS_MAP_VIEW.FISSURE,
    onSuccess(res) {
      const resData = res.data as ISummary;
      setTotalLength(resData.totalLength);
    },
  });

  const CustomTableCell = styled(TableCell)({
    fontWeight: 'bold',
    color: theme.palette.mode === 'dark' ? '#fff !important' : '#6E6B7B !important',
  });

  const renderAnalyticTab = (
    <Table>
      <TableBody>
        {tableData.map((row) => (
          <TableRow key={row.key} sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontWeight: 'bold' }}>
            <CustomTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
              {row.label}
            </CustomTableCell>
            <CustomTableCell align="center" sx={{ fontWeight: 'bold' }}>
              {row.value}
            </CustomTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.FISSURE}
      analyticTab={renderAnalyticTab}
      layerTab={<></>}
      isShowDownloadButton={false}
    />
  );
};

export default FissureAnalytics;
