import { yupResolver } from '@hookform/resolvers/yup';
import { PersonOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import { DashboardIcon } from 'assets/icons';
import { ISocialLogin } from 'common/defines/clients';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isValidToken } from 'services/auth';
import { sendLoginRequest, socialLogin } from 'services/clients/apiClient.services';
import { checkSuperAdmin } from 'store/slices/superAdminSlice';
import * as yup from 'yup';
import { ILoginFormData } from '../../common/defines/auth';
import { useAppDispatch } from '../../store/hooks';
import { clearAuthState, setAccountInfo } from '../../store/slices/authSlice';
import GoogleLoginSocial from './Google.login';
import { useLoginStyle } from './LoginStyle';
import { MicrosoftLoginSocial } from './Microsoft.login';
import TwitterLoginSocial from './Twitter.login';

const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  crossLineStyles: {
    borderTop: 1,
    width: '94px',
    mt: 1.25,
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
  textStyles: {
    fontSize: '14px',
    height: '17px',
  },
};

const loginValidationSchema = yup
  .object({
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup.string().required('Password is required'),
  })
  .required();

const LoginPage = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const isAuth = isValidToken();
  const navigate = useNavigate();
  const classes = useLoginStyle();
  const [showPassword, setShowPassword] = useState(false);
  const darkModeTheme1 = useTheme();
  const { i18n } = useTranslation();
  const checkDarkMode = darkModeTheme1.palette.mode === 'dark';
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ILoginFormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginValidationSchema),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginAccount = useMutation((loginData: ILoginFormData) => sendLoginRequest(loginData), {
    onSuccess: (accountData) => {
      const isFirstLogin = accountData.user.isFirstLogin;
      const isSuperAdmin = accountData.user.isSuperAdmin;
      const isSuperUser = accountData.user.isSuperUser;
      const localTheme = localStorage.getItem('theme');
      if (isFirstLogin && !isSuperAdmin && !isSuperUser) {
        navigate('/waiting-approval');
        return;
      }
      if (!localTheme && accountData?.user?.theme) {
        localStorage.setItem('theme', accountData.user.theme);
        window.location.reload();
      } else if (localTheme && accountData?.user?.theme && localTheme !== accountData.user.theme) {
        localStorage.setItem('theme', accountData.user.theme);
        window.location.reload();
      } else if (!localTheme && !accountData?.user?.theme) {
        localStorage.setItem('theme', 'light');
        window.location.reload();
      }
      toast.success('Login success', { toastId: 1 });
      navigate('/landing');
      dispatch(setAccountInfo(accountData));
      dispatch(checkSuperAdmin(accountData.user.isSuperAdmin));
    },
    onError: () => {
      toast.error('Invalid Email or password.', { toastId: 1 });
      dispatch(clearAuthState());
    },
  });

  const handleSubmitForm: SubmitHandler<ILoginFormData> = (data) => {
    handleLoginAccount.mutate(data);
  };

  useEffect(() => {
    isAuth && navigate('/landing');
  }, [isAuth, navigate]);

  useEffect(() => {
    const userAccount = JSON.parse(localStorage.getItem('account') || '{}');
    const isFirstLogin = userAccount.isFirstLogin;
    const providerId = userAccount.providerId;
    const isRequireField = userAccount.jobTitle;
    const isSuperAdmin = userAccount.isSuperAdmin;
    const isSuperUser = userAccount.isSuperUser;

    if (isFirstLogin) {
      if (providerId && !isRequireField) {
        navigate('/request-infomation');
        return;
      }
      if (!isSuperAdmin && !isSuperUser) {
        navigate('/waiting-approval');
        return;
      }
    }
  }, [navigate]);

  useEffect(() => {
    return () => {
      dispatch(clearAuthState());
    };
  }, [dispatch]);

  // reset language into English
  useEffect(() => {
    i18n.changeLanguage('en');
  }, [i18n]);

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value.trim());
  };
  const mutation = useMutation((data: ISocialLogin) => socialLogin(data), {
    onSuccess: (data) => {
      const isFirstLogin = data.user.isFirstLogin;
      const providerId = data.user.providerId;
      const isRequireField = data.user.jobTitle;
      const isStatus = data.user.status;
      if (isFirstLogin) {
        if (providerId && !isRequireField && isStatus === true) {
          navigate('/request-infomation');
          return;
        } else if (providerId && !isRequireField && isStatus === false) {
          navigate('/add-email');
          return;
        }
        navigate('/waiting-approval');
        return;
      }

      const token = localStorage.getItem('token');
      const isAuthenticated = localStorage.getItem('isAuthenticated');
      const sessionLogin = localStorage.getItem('sessionLogin');
      if (token && isAuthenticated && sessionLogin && !isFirstLogin) navigate('/landing');
    },
    onError: (error) => {
      // toast.error('Login failed');
    },
  });

  return (
    <>
      {/* {isLoggingIn && <LoadingOverlay />} */}
      <Typography
        component="div"
        sx={{
          height: '100vh',
          backgroundColor: checkDarkMode ? '#1F1F1F' : '#f9f9f9',
          display: ' flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Stack
          direction="row"
          sx={{
            height: 'fit-content',
            maxWidth: '1130px',
            borderRadius: '10px',
            backgroundColor: checkDarkMode ? '#3C4043' : '#fff',
            py: 5,
          }}>
          <Box
            alignItems="center"
            className={classes.baseStyles}
            sx={{
              width: '500px',
              pt: '20px',
            }}>
            <Typography
              component="div"
              sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <DashboardIcon />
              <Typography className={classes.brandStyles} sx={{ px: 2 }}>
                {' '}
                Agrimor
              </Typography>
            </Typography>
            <Typography
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '310px',
                ...styles.formStyles,
                textAlign: 'center',
              }}
              onSubmit={handleSubmit(handleSubmitForm)}>
              <Typography
                component="div"
                sx={{
                  mt: '52px',
                  mx: 'auto',
                }}>
                <Avatar sx={{ height: '75px', width: '75px' }} />
              </Typography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '30px',
                        ...styles.labelStyles,
                      }}>
                      Email
                    </Typography>
                    <TextField
                      type="email"
                      placeholder="someone@gmail.com"
                      sx={{
                        mt: '4px',
                        '& .MuiOutlinedInput-input': {
                          padding: '16.5px 14px',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutline
                              sx={{
                                height: '24px',
                                width: '24px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'email')}
                    />
                    {errors.email && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.email.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                      }}>
                      Password
                    </Typography>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      placeholder="password"
                      sx={{
                        mt: '4px',
                        'input::-ms-reveal': {
                          display: 'none',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword}
                              sx={{
                                height: '24px',
                                width: '24px',
                              }}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'password')}
                    />
                    {errors.password && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.password.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <div className="items-end">
                <Typography
                  sx={{
                    float: 'right',
                    ...styles.textStyles,
                  }}>
                  <Link
                    onClick={() => navigate('/forgot-password')}
                    underline="none"
                    sx={{ color: '#9E9E9E', cursor: 'pointer' }}>
                    Forgot Password
                  </Link>
                </Typography>
              </div>
              <Box
                sx={{
                  mt: '20px',
                }}>
                You don't have an account?
                <Button onClick={() => navigate('/signup')}>Create an account</Button>
              </Box>
              {handleLoginAccount.isLoading ? (
                <LoadingButton
                  loading
                  loadingPosition="start"
                  className={classes.loginBtn}
                  disabled={handleLoginAccount.isLoading}
                  variant="outlined">
                  Please wait...
                </LoadingButton>
              ) : (
                <Button type="submit" variant="contained" className={classes.loginBtn}>
                  Sign In
                </Button>
              )}
            </Typography>
            <Box sx={{ m: 2.5, mx: 'auto' }}>
              <Stack direction="row" justifyContent="center" display="flex" sx={{ ...styles.formStyles }}>
                <Box
                  sx={{
                    mr: 1.25,
                    ...styles.crossLineStyles,
                  }}
                />
                <Typography
                  component="span"
                  sx={{
                    ...styles.textStyles,
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  or continue with
                </Typography>
                <Box
                  sx={{
                    ml: 1.25,
                    ...styles.crossLineStyles,
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={5}
                justifyContent="center"
                sx={{ py: 2.5, ...styles.formStyles, height: '20px' }}>
                <MicrosoftLoginSocial mutation={mutation} />
                {/* <FacebookLoginSocial mutation={mutation} /> */}
                <GoogleLoginSocial mutation={mutation} />
                <TwitterLoginSocial mutation={mutation} />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Typography>
    </>
  );
};

export default LoginPage;
