import { Box, Button, Grid, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import CommonTextField from 'components/Common/CommonTextField';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { IEditWorkspace } from 'interfaces/workspace';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createNewWorkspace, deleteWorkspace, editWorkspaceInfo, getWorkspaceInfo } from 'services/workspaces';

interface AddWorkspaceModalProps {
  isShow: boolean;
  handleClose: () => void;
  editedWorkspaceId: string;
  setEditedWorkspaceId: (arg: string) => void;
}

const initialValues: IEditWorkspace = {
  name: '',
  description: '',
  _id: '',
};

const AddAndEditWorkspaceModal: FC<AddWorkspaceModalProps> = ({
  isShow,
  handleClose,
  editedWorkspaceId,
  setEditedWorkspaceId,
}) => {
  const mapViewStylesClasses = useMapViewStyle();
  const queryClient = useQueryClient();
  const [editedWorkspaceData, setEditedWorkspaceData] = useState<IEditWorkspace>(initialValues);

  const { handleSubmit, reset, control, setValue } = useForm<IEditWorkspace>({
    defaultValues: initialValues,
  });

  // set initial values of fields in edit mode
  useEffect(() => {
    for (const [key, value] of Object.entries(editedWorkspaceData)) {
      setValue(key as keyof IEditWorkspace, value);
    }
  }, [editedWorkspaceData, setValue]);

  useQuery([QUERY_KEY.GET_WORKSPACE_INFO], () => getWorkspaceInfo(editedWorkspaceId), {
    onSuccess(res) {
      setEditedWorkspaceData(res.data);
    },
    enabled: !!editedWorkspaceId,
  });

  const createWorkspaceMutation = useMutation(createNewWorkspace, {
    onSuccess: () => {
      toast.success('Create new workspace successful');
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_WORKSPACE]);
    },
  });

  const editWorkspaceMutation = useMutation(editWorkspaceInfo, {
    onSuccess: () => {
      toast.success('Edit workspace information successful');
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_WORKSPACE]);
    },
  });

  const deleteWorkspaceMutation = useMutation(deleteWorkspace, {
    onSuccess() {
      toast.success('Delete workspace successful');
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_WORKSPACE]);
    },
  });

  const onCloseModal = () => {
    reset(initialValues);
    setEditedWorkspaceId('');
    setEditedWorkspaceData(initialValues);
    handleClose();
  };

  const onDeleteWorkspace = () => {
    deleteWorkspaceMutation.mutate(editedWorkspaceId);
    onCloseModal();
  };

  const onSubmit = (data: IEditWorkspace) => {
    const { description, name } = data;
    editedWorkspaceId ? editWorkspaceMutation.mutate(data) : createWorkspaceMutation.mutate({ description, name });
    onCloseModal();
  };

  return (
    <CommonModal isShow={isShow} handleClose={onCloseModal}>
      <Box sx={{ width: '600px', p: '32px' }}>
        <Typography className={mapViewStylesClasses.titleInput} sx={{ mb: '24px' }}>
          {editedWorkspaceId ? 'Edit Workspace' : 'Add Workspace'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing="8px">
              <Grid item xs={3}>
                <Typography sx={{ mr: '8px' }}>Workspace name: </Typography>
              </Grid>
              <Grid item xs={9}>
                <CommonTextField
                  name="name"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Name is required',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ mr: '8px' }}>Description: </Typography>
              </Grid>
              <Grid item xs={9}>
                <CommonTextField name="description" control={control} />
              </Grid>
            </Grid>
            <Box sx={{ mt: '16px', textAlign: 'right' }}>
              {editedWorkspaceId && (
                <Button variant="contained" color="error" size="small" sx={{ mr: '12px' }} onClick={onDeleteWorkspace}>
                  Delete
                </Button>
              )}
              <Button type="submit" variant="contained" size="small">
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default AddAndEditWorkspaceModal;
