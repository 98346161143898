import { Grid, Tooltip } from '@mui/material';
import { LandUsageIcon } from 'assets/icons/MapView';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { AnalyticCardTab } from 'components/MapView/RightBar/AnalyticsTab/AnalyticCardTab';
import { QUERY_KEY } from 'constants/constants';
import { FC, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getListWorkspaceAnalysis } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearCircumferenceAnalytics } from 'store/slices/map-view/circumferenceAnalytics';
import { clearCrownAreaAnalytics } from 'store/slices/map-view/crownAreaAnalytics';
import { setDataStandCount } from 'store/slices/mapViewSlice';
import { changeAnalysisId, changeSelectedAnalysisName, tilingMapSelector } from 'store/slices/tilingMapSlice';

interface IGenerateAnalysis {
  _id: string;
  name: TYPE_ANALYTICS_MAP_VIEW;
}

interface ListAnalyticProps {}

const ListAnalytic: FC<ListAnalyticProps> = () => {
  const [dataAnalysisList, setDataAnalysisList] = useState<IGenerateAnalysis[]>([]);
  const dispatch = useAppDispatch();
  const { selectedTaskId, isGeneratingAnalysis, analysisId, selectedAnalysisName } = useAppSelector(tilingMapSelector);

  useQuery(
    [QUERY_KEY.GET_WORKSPACE_ANALYSIS_LIST, selectedTaskId, isGeneratingAnalysis],
    () => getListWorkspaceAnalysis(selectedTaskId!),
    {
      onSuccess(res) {
        const resData = res.data as IGenerateAnalysis[];
        const listAnalysis = resData.map((item) => ({ _id: item._id, name: item.name }));
        setDataAnalysisList(listAnalysis);
      },
      enabled: !!selectedTaskId && !isGeneratingAnalysis,
    }
  );

  const resetAnalyticData = useCallback(() => {
    dispatch(setDataStandCount([]));
    dispatch(clearCrownAreaAnalytics());
    dispatch(clearCircumferenceAnalytics());
  }, [dispatch]);

  // reset analysis when change field
  useEffect(() => {
    dispatch(changeAnalysisId(''));
    dispatch(changeSelectedAnalysisName(''));
    resetAnalyticData();
  }, [dispatch, resetAnalyticData, selectedTaskId]);

  // reset all data when change analysis
  useEffect(() => {
    resetAnalyticData();
  }, [analysisId, resetAnalyticData]);

  const handleClickSelectAnalytic = (_item: IGenerateAnalysis) => {
    dispatch(changeSelectedAnalysisName(_item.name === selectedAnalysisName ? '' : _item.name));
    dispatch(changeAnalysisId(_item._id === analysisId ? '' : _item._id));
  };

  return (
    <Grid container spacing={0.5} columns={24} sx={{ mt: 2 }}>
      {isGeneratingAnalysis ? (
        <WaitingPageAPI />
      ) : (
        dataAnalysisList.map((_item) => {
          return (
            <Tooltip title={_item.name} placement="bottom" key={_item._id}>
              <Grid item xs={4}>
                <AnalyticCardTab
                  key={_item._id}
                  icon={<LandUsageIcon width="28px" height="28px" isSelected={_item.name === selectedAnalysisName} />}
                  label={_item.name}
                  isSelected={_item.name === selectedAnalysisName}
                  onSelect={() => handleClickSelectAnalytic(_item)}
                />
              </Grid>
            </Tooltip>
          );
        })
      )}
    </Grid>
  );
};

export default ListAnalytic;
