import AddIcon from '@mui/icons-material/Add';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Box, Grid, Pagination, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SortDateEnum } from 'components/MapView/RightBar/IssuesTab/interfaces';
import { QUERY_KEY } from 'constants/constants';
import { IWorkspace, IWorkspacePagination } from 'interfaces/workspace';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getListWorkspace } from 'services/workspaces';
import AddAndEditWorkspaceModal from './AddAndEditWorkspaceModal';

interface IPaginationMetadata {
  currentPage: number;
  nextPage: number;
  pageSize: number;
  pageCount: number;
  totalCount: number;
}

const initialWorkspaces: IWorkspace[] = [{ _id: '', name: '', description: '' }];

const initialWorkspacePagination = {
  page: 1,
  paginationVersion: 2,
  perPage: 5,
  sortDate: SortDateEnum.DESC,
  workspaceId: '',
};

const AddWorkspaces = () => {
  const [isShowAddWorkspaceModal, setIsShowAddWorkspaceModal] = useState<boolean>(false);
  const [listWorkspace, setListWorkspace] = useState<IWorkspace[]>(initialWorkspaces);
  const [workspacePagination, setWorkspacePagination] = useState<IWorkspacePagination>(initialWorkspacePagination);
  const [paginationMetaData, setPaginationMetaData] = useState<IPaginationMetadata>({
    currentPage: 1,
    nextPage: 2,
    pageSize: 5,
    pageCount: 1,
    totalCount: 5,
  });
  const [editedWorkspaceId, setEditedWorkspaceId] = useState<string>('');

  const theme = useTheme();
  const navigate = useNavigate();

  const WorkspaceBox = styled(Box)({
    borderRadius: '5px',
    border: `1px solid ${theme.palette.divider} !important`,
    padding: '10px',
    backgroundColor: theme.palette.background.paper,
    height: '64px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.color.green5,
    },
  });

  useQuery([QUERY_KEY.GET_LIST_WORKSPACE, workspacePagination], () => getListWorkspace(workspacePagination), {
    onSuccess(res) {
      const data = res.data.data as IWorkspace[];
      setListWorkspace(data);
      setPaginationMetaData(res.data.metadata);
    },
  });

  const startIndex = (paginationMetaData.currentPage - 1) * paginationMetaData.pageSize + 1;
  const lastIndex = paginationMetaData.currentPage * paginationMetaData.pageSize;
  const endIndex = lastIndex >= paginationMetaData.totalCount ? paginationMetaData.totalCount : lastIndex;

  return (
    <Box>
      <Box sx={{ mb: '24px', mt: '8px' }}>
        <Typography variant="h1" mb={2}>
          Aero Insights
        </Typography>
        <Grid container spacing="16px">
          <Grid item xs={2}>
            <WorkspaceBox
              onClick={() => {
                setIsShowAddWorkspaceModal(true);
                setEditedWorkspaceId('');
              }}>
              <AddIcon />
            </WorkspaceBox>
          </Grid>
          {listWorkspace.map((item, i) => (
            <Grid item xs={2} key={i}>
              <WorkspaceBox
                key={i}
                sx={{ position: 'relative' }}
                onClick={() => navigate(`/aero-insights/${item._id}`)}>
                {item.name}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-10px',
                    right: '0px',
                    '&:hover': {
                      color: 'red',
                    },
                  }}>
                  <CreateOutlinedIcon
                    sx={{ fontSize: '20px', cursor: 'pointer' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsShowAddWorkspaceModal(true);
                      setEditedWorkspaceId(item._id);
                    }}
                  />
                </Box>
              </WorkspaceBox>
            </Grid>
          ))}
        </Grid>
        <AddAndEditWorkspaceModal
          isShow={isShowAddWorkspaceModal}
          handleClose={() => setIsShowAddWorkspaceModal(false)}
          editedWorkspaceId={editedWorkspaceId}
          setEditedWorkspaceId={setEditedWorkspaceId}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mr: '40px' }}>
        <Typography>
          Showing {startIndex} to {endIndex} of {paginationMetaData.totalCount} workspaces
        </Typography>
        <Pagination
          sx={{
            '& .MuiPagination-ul': {
              '& .Mui-selected': {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: 'white',
              },
              '& .Mui-selected:hover': {
                backgroundColor: '#23BE6AB0',
              },
              '& .MuiPaginationItem-icon': {
                color: (theme) => theme.palette.primary.main,
              },
            },
          }}
          count={paginationMetaData.pageCount}
          defaultPage={1}
          page={workspacePagination.page}
          onChange={(_, value) => {
            setWorkspacePagination((prev) => ({ ...prev, page: value }));
          }}
        />
      </Box>
    </Box>
  );
};

export default AddWorkspaces;
